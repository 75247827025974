
import device from "@/mixins/device.js";
import lang from "@/mixins/lang.js";
import { dateMixin } from "@/mixins/date.js";
import dynamicPagesParentsSlugs from "@/mixins/dynamic-pages-parents-slug";

export default {
  name: "HeroSearch",
  mixins: [device, lang, dateMixin, dynamicPagesParentsSlugs],
  props: {
    hero: {
      type: Object,
    },
  },
  data() {
    return {
      searchInput: "",
      searchActivated: false,
    };
  },
  methods: {
    closeSearchResults(event) {
      if (event.target.id !== "hero-search") {
        this.searchInput = "";
      }
    },
    buildPath(item) {
      if (item.parent && item.parent.length > 0) {
        return this.buildPath(item.parent[0]) + "/" + item.slug;
      } else {
        return item.slug;
      }
    },
  },
  computed: {
    searched() {
      return this.searchInput ? true : false;
    },
  },
};
