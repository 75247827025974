
import device from "@/mixins/device.js";
export default {
  name: "CardsApp",
  mixins: [device],
  props: {
    card: {
      type: Object,
    },
  },
};
