
import device from "@/mixins/device.js";

export default {
  name: "WidgetQuickLinks",
  mixins: [device],
  props: {
    widget: {
      type: Object,
    },
  },
};
