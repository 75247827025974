
export default {
  name: "WidgetTextList",
  props: {
    widget: {
      type: Object,
    },
    endpoint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      endpointData: null,
      endpointDataObject: null,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let endpoint = this.endpoint;

      if (endpoint.indexOf("http") > -1) {
        try {
          const response = await Promise.race([
            fetch(endpoint, {
              headers: {
                Authorization: `Bearer ${process.env.NUXT_ENV_AUTH_TOKEN}`,
              },
            }).then((res) => res.json()),
            new Promise((_, reject) => {
              setTimeout(() => {
                reject(new Error(`Request timed out: ${endpoint}`));
              }, 60000); // Timeout duration in milliseconds (60 seconds)
            }),
          ]);
          this.endpointData = (await response?.data) ?? response ?? null;
        } catch (err) {
          console.error("API Error:", err);
        }
      }
    },
    formatLocalized(num) {
      let million = Math.floor(num / 1000000);
      let thousand = Math.floor((num % 1000000) / 1000);
      let result = "";

      if (million > 0) {
        result += `${million} milyon `;
      }

      if (thousand > 0) {
        result += `${thousand} bin `;
      }

      result += `m³/gün`;

      return result.trim();
    },
  },
};
