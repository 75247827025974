
export default {
    /**
     * @param {endpoint} - içerik verilerinin url sorgularını sağlar.
     * Vue lifecycle `fetch` sırasında @param {this.$axios} ve @param {endpoint} kullanarak
     * Tüm veriler @param {endpointData} değişkenine atanır.
     */
  name: "WidgetApp",
  props: {
    widget: {
      type: Object,
    },
    endpoint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      endpointData: null,
    };
  },
  async fetch() {
    if (!!this.endpoint) {
      try {
        const response = await this.$axios.get(`${this.endpoint}`);
        this.endpointData = response.data.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
};
